





































































import Vue from 'vue'
import ODataTable from '@/components/ODataTable.vue'
import IODataTableHeader from '@/models/IODataTableHeader'
import ProductReleaseTable from './ProductReleaseTable.vue'
import IProduct from '../models/IProduct'
import IRelease from '../models/IRelease'
import IGraphQueryProduct from '../models/IGraphQueryProduct'

export default Vue.extend({
  props: {
    value: Array as () => IGraphQueryProduct[],
    filter: String
  },
  components: {
    ODataTable,
    ProductReleaseTable
  },
  data() {
    return {
      expanded: [],
      selected: [],
      loading: false,
      url: 'products?$expand=releases,editions,links($filter=migrationPath eq true)',
      filters: []
    }
  },
  computed: {
    headers(): IODataTableHeader[] {
      const headers: IODataTableHeader[] = [
        {
          text: 'Product',
          value: 'listingName',
          searchable: true,
          class: 'text-no-wrap'
        },
        {
          text: 'Policy Type',
          value: 'supportPolicy',
          class: 'text-no-wrap'
        },
        {
          text: 'Start Date',
          value: 'supportStartDate',
          format: 'date',
          class: 'text-no-wrap'
        },
        {
          text: 'Mainstream / Retirement',
          value: 'endDate1',
          format: 'date',
          class: 'text-no-wrap'
        },
        {
          text: 'Extended End',
          value: 'endDate2',
          format: 'date',
          class: 'text-no-wrap'
        },
        {
          text: 'Migration Path',
          value: 'links',
          class: 'text-no-wrap'
        }
      ]

      headers.push(
        {
          text: '',
          value: 'data-table-expand'
        })

      return headers
    },
    load(): Boolean {
      return true
    }
  },
  methods: {
    getTitle(item: IProduct) {
      if (!item || !item.supportPolicy) {
        return undefined
      }
      if (item.supportPolicy.startsWith('Modern')) {
        return 'Retirement Date'
      }
      return 'Mainstream Date'
    },
    getSupportPolicyUrl(policyName: string) {
      const policy = this.$store.getters['SupportPolicies/getSupportPolicyByName'](policyName)
      return policy.externalReferenceUrl
    },
    onRowClicked(item: any, options: any) {
      if (item.releases.length > 1) {
        options.expand(!options.isExpanded)
      }
    },
    onReleaseSelected(product: IProduct, event: any) {
      this.onProductSelectionChanged(product, event.item, event.value)
    },
    onSelectionChanged(event: any) {
      const releases = event.item.releases as IRelease[]
      releases.forEach(release => {
        this.onProductSelectionChanged(event.item, release, event.value)
      })
    },
    onProductSelectionChanged(product: IProduct, release: IRelease, selected: boolean) {
      if (!this.loading) {
        this.$emit('selection-changed', {
          product,
          release,
          selected
        })
      }
    },
    onItemsChanged(products: IProduct[]) {
      if (products) {
        const selectedProducts = []

        // get distinct set of listing ids
        const listingIds = [...new Set(this.value.filter(x => x.includeInReport).map(x => x.listingId))]

        listingIds.forEach(listingId => {
          const product = products.find(x => x.listingId === listingId)
          if (product) {
            selectedProducts.push(product)
          }
        })

        this.selected = selectedProducts
      }
    }
  },
  watch: {
    value: {
      handler(newValue: IGraphQueryProduct[]) {
        this.loading = true
        try {
          const table = this.$refs.table as any
          const products = table.result.value as IProduct[]
          const selectedProducts = []

          // get distinct set of listing ids
          const listingIds = [...new Set(newValue.filter(x => x.includeInReport).map(x => x.listingId))]

          listingIds.forEach(listingId => {
            const product = products.find(x => x.listingId === listingId)
            if (product) {
              selectedProducts.push(product)
            }
          })

          this.selected = selectedProducts
        } finally {
          this.loading = false
        }
      },
      deep: true
    }
  }
})
