





























import Vue from 'vue'
import { DataTableHeader } from 'vuetify'
import IGraphQueryProduct from '../models/IGraphQueryProduct'
import IProduct from '../models/IProduct'
import IRelease from '../models/IRelease'

export default Vue.extend({
  props: {
    value: Array as () => IGraphQueryProduct[],
    releases: Array as () => IRelease[],
    graphQueryProduct: Object as () => IProduct
  },
  data() {
    return {
      selected: []
    }
  },
  computed: {
    headers(): DataTableHeader[] {
      const headers: DataTableHeader[] = [
        {
          text: 'Release Name',
          value: 'name'
        },
        {
          text: 'Start Date',
          value: 'startDate'
        },
        {
          text: 'End Date',
          value: 'endDate'
        }
      ]
      return headers
    }
  },
  methods: {
    setSelected(newValue: IGraphQueryProduct[]) {
      const selectedReleases = []

      // get graph query products for this listing
      const graphQueryProducts = newValue.filter(x => x.listingId === this.graphQueryProduct.listingId)

      graphQueryProducts.forEach(gqp => {
        if (gqp.includeInReport) {
          const release = this.releases.find(x => x.id === gqp.releaseId)
          if (release) {
            selectedReleases.push(release)
          }
        }
      })

      this.selected = selectedReleases
    }
  },
  watch: {
    value: {
      handler(newValue: IGraphQueryProduct[]) {
        this.setSelected(newValue)
      },
      deep: true
    }
  },
  mounted() {
    this.setSelected(this.value)
  }
})
