















import Vue from 'vue'
import { createNamespacedHelpers } from 'vuex'
import IDateFormat from '../models/IDateFormat'
const { mapGetters } = createNamespacedHelpers('DateFormats')

export default Vue.extend({
  props: {
    value: [String],
    label: {
      type: String,
      default: 'Date Format'
    },
    disabled: Boolean,
    filter: {
      type: Function,
      default() {
        return true
      }
    }
  },
  computed: {
    ...mapGetters(['results']),
    items(): IDateFormat[] {
      const items = this.results.filter(this.filter) as IDateFormat[]
      return items
    },
    tooltip(): string {
      const dateFormats = this.results as IDateFormat[]
      const selectedDateFormat = dateFormats.find((dateFormat) => dateFormat.shortDateFormat === this.value)
      return selectedDateFormat?.shortDateFormat
    }
  }
})
