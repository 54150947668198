import Vue from 'vue'
import ODataResult from '@/models/ODataResult'
import Axios, { CancelTokenSource } from 'axios'
import { isObject } from 'lodash-es'
import IInfiniteLoadEvent from '@/models/IInfiniteLoadEvent'
import IODataQuery from '@/models/IODataQuery'
import { IODataRequest } from '@/models/IODataRequest'

export default Vue.extend({
  data() {
    return {
      loading: false,
      loadingMore: false,
      error: null as Error,
      cancelTokenSource: null as CancelTokenSource
    }
  },
  methods: {
    async get<T>(url: string, query: IODataQuery = {}) {
      if (this.cancelTokenSource) {
        this.cancelTokenSource.cancel()
      }
      query.$count = true
      query.$top = query.$top ?? 10
      if (query.$top > 0) {
        query.$skip = query.$skip || 0
      }
      this.cancelTokenSource = Axios.CancelToken.source()
      const request: IODataRequest = {
        url,
        params: query,
        method: 'get',
        cancelToken: this.cancelTokenSource.token
      }
      return new ODataResult<T>(await this.request<T>(request), request)
    },
    async getSingle<T>(url: string, query: IODataQuery = {}) {
      const request: IODataRequest = {
        url,
        params: query,
        method: 'get'
      }
      return (await this.request<T>(request)).data
    },
    async getCount(url: string, query?: IODataQuery) {
      query.$count = true
      query.$top = 0
      const request: IODataRequest = {
        url,
        params: query,
        method: 'get'
      }
      return new ODataResult(await this.request(request), request).count
    },
    async getMore<T>(lastResult: ODataResult<T>, e: IInfiniteLoadEvent, mapItem?: (item: any) => T) {
      this.loadingMore = true

      const request = lastResult.request
      const skip = request.params.$skip
      const top = request.params.$top

      // check to see if all results have been loaded
      if ((skip + top) >= lastResult.count) {
        e.loaded()
        e.complete()
        return
      }

      // get more results
      request.params.$skip += top
      const result = await this.get<T>(request.url, request.params)
      e.loaded()

      // apply changes to new items (i.e. new up item in a class)
      if (mapItem) {
        result.value = result.value.map(mapItem)
      }

      // combine old results with new results
      lastResult.value.push(...result.value)

      this.loadingMore = false

      return lastResult
    },
    async post<T>(url: string, data?: any) {
      const request: IODataRequest = {
        url,
        data,
        method: 'post'
      }
      return this.request<T>(request)
    },
    async put<T>(url: string, data: any) {
      const request: IODataRequest = {
        url,
        data,
        method: 'put'
      }
      return this.request<T>(request)
    },
    async patch<T>(url: string, data: any) {
      const request: IODataRequest = {
        url,
        data,
        method: 'patch'
      }
      return this.request<T>(request)
    },
    async delete<T>(url: string, data?: any) {
      const request: IODataRequest = {
        url,
        data,
        method: 'delete'
      }
      return this.request<T>(request)
    },
    async request<T>(request: IODataRequest) {
      try {
        this.loading = true
        // clean the data by removing properties that start with $_
        const replacer = (key: string, value: any) => {
          if (key.startsWith('$_')) {
            return undefined
          }
          if (request.flatten && key && isObject(value)) {
            return undefined
          }
          return value
        }
        if (request.data) {
          request.data = JSON.parse(JSON.stringify(request.data, replacer))
        }

        // need to delete empty odata params or request will fail
        const params = request.params
        if (params) {
          for (const key in params) {
            if (!params[key] && params[key] !== 0) {
              delete params[key]
            }
          }
        }

        // add concurrency to header if provided
        request.headers = request.headers ?? {}
        if (request.concurrency) {
          request.headers['If-Match'] = request.concurrency
        }

        const result = await Axios.request<T>(request)
        this.loading = false
        return result
      } catch (err: any) {
        if (!err.__CANCEL__) {
          this.error = err
          this.$store.dispatch('showErrorAlert', err)
          this.loading = false
        }
        throw err
      }
    }
  }
})
