




































import { createNamespacedHelpers } from 'vuex'
import { cloneDeep } from 'lodash-es'
import ODataMixin from '@/mixins/ODataMixin'
import ProductPanel from '../components/ProductPanel.vue'
import QuerySummary from '../components/QuerySummary.vue'
import IQuery from '../models/IQuery'
import IProductSelectionChanged from '../models/IProductSelectionChanged'
import IGraphQueryProduct from '../models/IGraphQueryProduct'
import IProduct from '../models/IProduct'
import IRelease from '../models/IRelease'
import { RawLocation } from 'vue-router'
import AlertType from '@/models/AlertType'

const { mapActions } = createNamespacedHelpers('Queries')

// clean the data by removing properties that start with $_
const replacer = (key: string, value: any) => {
  if (key.startsWith('$_')) {
    return undefined
  }
  return value
}

export default ODataMixin.extend({
  components: {
    ProductPanel,
    QuerySummary
  },
  data() {
    return {
      query: null as IQuery,
      originalQuery: null as IQuery,
      productFilter: '' as String,
      loading: false,
      loadingText: 'Loading...'
    }
  },
  computed: {
    hasChanges(): boolean {
      const originalString = JSON.stringify(this.originalQuery, replacer)
      const currentString = JSON.stringify(this.query, replacer)

      return originalString !== currentString
    }
  },
  methods: {
    ...mapActions({
      updateQuery: 'saveQuery',
      removeProduct: 'removeProduct',
      generateDocument: 'generateDocument',
      copyQuery: 'copyQuery'
    }),
    loadingQuerySummary(loading: boolean) {
      this.loading = loading
      if (!loading) {
        this.originalQuery = cloneDeep(this.query)
      }
    },
    async generateQueryDocument() {
      this.loadingText = 'Saving report...'
      this.loading = true
      try {
        try {
          await this.saveQuery(this.query)
        } catch (err: any) {
          const error = {
            type: AlertType.Error,
            name: 'Error Saving Report',
            messages: [`Error Saving Report: ${err.message}`]
          }
          this.$store.dispatch('showAlert', error)
          throw err
        }
        try {
          this.loadingText = 'Generating report...'
          this.loading = true
          await this.generateDocument(this.query)
        } catch (err: any) {
          const error = {
            type: AlertType.Error,
            name: 'Error Generating Report',
            messages: [`Error Generating Report: ${err.message}`]
          }
          this.$store.dispatch('showAlert', error)
          throw err
        }
      } finally {
        this.loading = false
        this.loadingText = 'Loading...'
      }
    },
    async getQuery(id?: number) {
      id = id || this.query.id

      this.originalQuery = await this.getSingle<IQuery>(`GraphQueries/${id}?$expand=graphQueryProducts`)
      this.query = cloneDeep(this.originalQuery)
    },
    async saveQuery(query: IQuery) {
      if (query.name && query.name.length > 0) {
        query.isTemporary = false
      } else {
        query.isTemporary = true
      }
      // if (query.id) {
      //   if (query.$_copy) {
      //     query.id = await this.copyQuery(query)
      //     await this.updateUrl(query, false)
      //   } else {
      //     // update
      //     await this.updateQuery(query)
      //   }
      // } else {
      //   // create
      //   query.id = await this.updateQuery(query)
      //   await this.updateUrl(query, true)
      // }
      const useQueryString = query.id === 0
      query.id = await this.updateQuery(query)
      await this.updateUrl(query, useQueryString)
      await this.getQuery(query.id)
    },
    async updateUrl(query: IQuery, useQueryString: boolean) {
      const route = this.$route
      if (!route.path.endsWith(`${query.id}`)) {
        if (useQueryString) {
          const newRoute: RawLocation = {
            name: route.name,
            params: route.params,
            query: {
              ...route.query,
              id: `${query.id}`
            } || {},
            hash: route.hash,
            path: route.path
          }
          if (JSON.stringify(route.query) !== JSON.stringify(newRoute.query)) {
            await this.$router.replace(newRoute)
          }
        } else {
          const newRoute: RawLocation = {
            name: route.name,
            params: {
              id: `${query.id}`
            },
            query: route.query,
            hash: route.hash,
            path: route.path
          }
          if (JSON.stringify(route.params) !== JSON.stringify(newRoute.params)) {
            await this.$router.replace(newRoute)
          }
        }
      }
    },
    onProductSelectionChanged(item: IProductSelectionChanged) {
      if (item.selected) {
        item.product.releases.forEach(release => {
          const existing = this.query.graphQueryProducts.find(x => x.listingId === item.product.listingId && x.releaseId === release.id)
          if (!existing) {
            const gqp = this.getGraphQueryProduct(item.product, release, release === item.release)
            this.query.graphQueryProducts.push(gqp)
          }
        })
      } else {
        const gcp = this.query.graphQueryProducts.find(x => x.listingId === item.product.listingId && x.releaseId === item.release.id)
        gcp.includeInReport = false
      }
      const querySummary = this.$refs['query-summary'] as any
      querySummary.updateSummary(item)
    },
    getGraphQueryProduct(product: IProduct, release: IRelease, includeInReport: boolean) : IGraphQueryProduct {
      return {
        graphQueryId: this.query.id,
        $_productName: product.listingName,
        $_releaseName: release.name,
        listingId: product.listingId,
        releaseId: release.id,
        includeInReport
      } as IGraphQueryProduct
    },
    onAddProduct(graphQueryProduct: IGraphQueryProduct) {
      const existing = this.query.graphQueryProducts.find(x => x.listingId === graphQueryProduct.listingId && x.releaseId === graphQueryProduct.releaseId)
      if (existing) {
        existing.includeInReport = true
      } else {
        this.query.graphQueryProducts.push(graphQueryProduct)
        graphQueryProduct.includeInReport = true
      }
    },
    onSelectProduct(graphQueryProduct: IGraphQueryProduct, selected: boolean) {
      const existing = this.query.graphQueryProducts.find(x => x.listingId === graphQueryProduct.listingId && x.releaseId === graphQueryProduct.releaseId)
      existing.includeInReport = selected
    },
    onRemoveProduct(graphQueryProduct: IGraphQueryProduct) {
      const index = this.query.graphQueryProducts.findIndex(x => x.listingId === graphQueryProduct.listingId && x.releaseId === graphQueryProduct.releaseId)
      this.query.graphQueryProducts.splice(index, 1)
    },
    async onTemplateChanged(id) {
      const getTemplate = this.$store.getters['Templates/getById']
      const template = getTemplate(id)
      const dateFormat = await this.$store.dispatch('DateFormats/getByLocale', template.dmclanguageCode)
      this.query.graphTemplateId = id
      this.query.dateFormat = dateFormat.shortDateFormat
    }
  },
  async created() {
    this.loadingText = 'Loading...'
    this.loading = true
    try {
      const selectAllReleases = Boolean(this.$route.params.graphQueryProducts) ?? false
      const graphQueryProducts = (this.$route.params.graphQueryProducts ?? []) as IGraphQueryProduct[]
      const reportName = this.$route.params.reportName
      if (this.$route.params.id) {
        this.getQuery(+this.$route.params.id)
      } else if (this.$route.query.id !== undefined) {
        this.getQuery(+this.$route.query.id)
      } else {
        const defaultTemplate = this.$store.getters['Templates/default']
        const dateFormat = await this.$store.dispatch('DateFormats/getByLocale', defaultTemplate.dmclanguageCode)
        this.query = {
          $_selectAllReleases: selectAllReleases,
          graphQueryProducts,
          isTemporary: true,
          dateFormat: dateFormat.shortDateFormat,
          graphTemplateId: defaultTemplate.id,
          name: reportName
        } as IQuery
      }
    } finally {
      this.loading = false
    }
  },
  async beforeRouteLeave(to, from, next) {
    const message = 'Click the Generate and Save button to save your progress. The report will be available on the Saved Report page. If you leave without generating and saving, progress will be lost.'
    if (
      !this.hasChanges ||
      (await this.$confirm(message, {
        title: 'Unsaved Changes',
        buttonTrueText: 'Ok',
        buttonFalseText: 'Cancel'
      }))
    ) {
      next()
    } else {
      next(false)
    }
  }
})
